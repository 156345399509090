<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="350"
    :retain-focus="false"
    :value="dialog"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--header">
          <v-icon color="primary" size="40"> fa-solid fa-question </v-icon>
        </div>
        <div class="b-dialog-question-card--body">
          <h3 class="primary--text">Atenção</h3>
          <p>
            Para fechar a fatura com valor de<br />
            <b>
              {{ formatMoney(totalInvoiceValue) }}
            </b>
            , informe o número da fatura.
          </p>
          <v-text-field
            id="invoiceCode"
            name="invoiceCode"
            label="Digite o número"
            autofocus
            clearable
            hide-details
            outlined
            dense
            required
            :rules="[required]"
            v-model="invoiceCode"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Cancelar"
            @click="closeModal(false)"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Continuar"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { formatMoney } from '@/helpers/formatting';
import { showMessage } from '@/helpers/messages';
import { InvoiceService } from '@/services/api/invoice';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    invoiceId: {
      type: String,
      require: true
    },
    totalInvoiceValue: {
      type: Number,
      require: true
    }
  },

  data: () => ({
    valid: true,
    invoiceCode: null
  }),

  methods: {
    formatMoney,

    closeModal(redirect) {
      this.$emit('closeModal', redirect);
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const invoiceService = new InvoiceService();
        const { status } = await invoiceService.postInvoiceCode({
          invoiceId: this.invoiceId,
          invoiceCode: this.invoiceCode
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
